import {
    AfterViewInit,
    Component,
    ComponentRef,
    ViewChild
} from '@angular/core';

import { LandingPageDirective } from './directive/landing-page.directive';
import { ConfigService } from 'app/services/config.service';
import { ActivatedRoute } from "@angular/router";
import { WebSocketService } from 'app/services/web-socket.service';

@Component({
    selector: 'app-landing-page',
    template: `
        <app-loading-landing *ngIf="isLoading"></app-loading-landing>
        <ng-template appLandingPage></ng-template>
    `,
})

export class LandingPageComponent implements AfterViewInit {
    @ViewChild(LandingPageDirective, {static: true}) directive!: LandingPageDirective;
    designComponent: ComponentRef<any> | null = null;
    isLoading: boolean = true;
    constructor(
        protected config: ConfigService,
        private route: ActivatedRoute,
        private ws: WebSocketService ) {}

    async getDesign(id: string) {
        switch (id) {
            case '4e9761a1-c136-4125-8706-d5de851a2e6f':
                const { LandingPageComponent: X } = await
                    import(`./design/4e9761a1-c136-4125-8706-d5de851a2e6f/landing-page.component`);
                return X;
            case '404':
                const { NotFoundComponent: NotFound } = await
                    import(`../not-found/not-found.component`);
                return NotFound;
            default:
                throw new Error(`could not load module from design ${id}`);
        }
    }

    async ngAfterViewInit() { // TODO: due to being late with task, this also needs to be considered.
        this.config.setProjectId(this.route.snapshot.paramMap.get('project_id'));

        try { // TODO: fast solution but needs to be discussed.
            this.config.setProjectConfig((await this.config.fetchConfiguration()).body);
            await this.ws.initConnection();

            const promises = this.config.getLanguages().map(async (lang) => {
                return await this.config.fetchVideos(this.config.setUpConfigVideos(lang.locale));
            });

            const allVideos = await Promise.all(promises);
            this.config.setVideos(allVideos.flat())

            /*const user = {
                id: 1,
                first_name: 'Mile',
                last_name: 'Vucan',
                nationality: 'Turkey',
                current_residency: 'Abu Dhabi',
                original_residency: '',
                age: 24,
                gender: 'Male',
                policy: 'Flexi'
            };

            await this.config.createAiAgent(user);*/
            await this.renderDesign(this.config.getDesignId());
            this.isLoading = false;
        } catch (e: any) {
            await this.renderDesign("404");
            this.isLoading = false;
            throw new Error(e.toString());
        }
    }

    async renderDesign (designId: string) {
        const design = await this.getDesign(designId);
        this.directive.viewContainerRef.clear();
        // @ts-ignore
        this.designComponent = await this.directive.createComponent(design);
    }
}
